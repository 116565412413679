import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { object, string } from 'yup'

import {
  getBusinessEmailFromLocalStorage,
  useForm,
} from '../../utils/form/useForm'
import { cleanString, useServerSideSafeValue } from '../../utils/helpers'
import GoogleAnalyticsHiddenFields from '../GoogleAnalyticsHiddenFields'
import Link from '../Link'
import Button from '../UI/Button/Button'
import Wrapper from '../UI/Wrapper'
import { NewsletterSection, StyledContainer } from './styles'

const NewsletterSignupNew = (props: any) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { content, className = '' } = props

  const { value: localStorageBusinessEmail, isReady } = useServerSideSafeValue(
    () => getBusinessEmailFromLocalStorage(),
  )

  const initialValues = {
    subscribeEmail: localStorageBusinessEmail ? localStorageBusinessEmail : '',
  }

  const emailError = cleanString(content?.error_message?.[0]?.copy)

  const validationSchema = () =>
    object().shape({
      subscribeEmail: string().required(emailError).email(emailError),
    })

  const formId = 'subscribeForm'

  const form = useForm({
    initialValues,
    formId,
    endpoint: 'newsletter',
    validationSchema,
    onSuccess: () => {
      setIsSubmitted(true)
    },
    mapper: ({ subscribeEmail }) => ({
      email: subscribeEmail,
    }),
    emailField: 'subscribeEmail',
  })

  useEffect(() => {
    if (isReady) {
      form.setValues(initialValues)
    }
  }, [isReady])

  return (
    <NewsletterSection className={className}>
      <Wrapper>
        <Typography
          className="headline"
          color="inherit"
          component="h2"
          variant="h3"
        >
          {content?.headline}
        </Typography>
        <Typography
          className="body"
          color="inherit"
          component="p"
          variant="body1"
        >
          {content?.short_description}
        </Typography>
        <form
          className="subscribeForm"
          id="subscribeForm"
          onSubmit={form.handleSubmit}
        >
          {isSubmitted ? (
            <StyledContainer>
              <Typography className="successMessage" component="p" variant="h2">
                {content?.confirmation_message?.[0]?.copy
                  ? cleanString(content?.confirmation_message?.[0]?.copy)
                  : null}
              </Typography>
            </StyledContainer>
          ) : (
            <StyledContainer>
              <GoogleAnalyticsHiddenFields />
              <TextField
                className="subscribeEmail"
                error={
                  form.touched.subscribeEmail
                    ? Boolean(form.errors.subscribeEmail)
                    : null
                }
                helperText={
                  form.touched.subscribeEmail
                    ? form.errors.subscribeEmail
                    : null
                }
                id="subscribeEmail"
                label={cleanString(content?.first_name_label?.[0]?.copy)}
                name="subscribeEmail"
                onChange={form.handleChange}
                value={form.values.subscribeEmail}
                variant="filled"
              />
              <Button type="submit" variant="secondary">
                {content?.submit_cta?.[0]
                  ? cleanString(content?.submit_cta?.[0]?.copy)
                  : 'Subscribe'}
              </Button>
              <Typography
                className="mouseType"
                component="span"
                variant="caption"
              >
                <Link link={content?.small_text?.[0]}>
                  {content?.small_text?.[0]?.text}
                </Link>
              </Typography>
            </StyledContainer>
          )}
        </form>
      </Wrapper>
    </NewsletterSection>
  )
}

export default NewsletterSignupNew

import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const NewsletterSection = styled.section`
  background-color: ${rspTheme.palette.primary.dark};
  color: #ffffff;
  padding: 72px 0;

  .headline {
    margin-bottom: 40px;
    max-width: 36ch;
  }

  .body {
    max-width: 75ch;
  }

  .subscribeForm {
    margin-top: 48px;
    max-width: 480px;
  }

  .mouseType {
    font-weight: 300;
    a {
      color: inherit;
      text-decoration-color: transparent;
      transition: 0.2s ease-in-out;

      &:hover,
      &:focus-visible {
        text-decoration-color: inherit;
      }
    }
  }

  &.technology {
    background-color: ${rspTheme.palette.primary.background};
    color: ${rspTheme.palette.primary.dark};

    .headline {
      max-width: 550px;
      margin: 0 auto;
    }
    .subscribeForm {
      max-width: 550px;
      margin: 48px auto 0;
    }

    #subscribeEmail {
      margin-bottom: 0;
      box-shadow: rgb(0 0 0 / 8%) 0px 0px 24px;
    }

    button[type='submit'] {
      background-color: ${rspTheme.palette.primary.main};
      border-color: ${rspTheme.palette.primary.main};

      &:hover,
      &:focus {
        color: ${rspTheme.palette.primary.main};
        background-color: ${rspTheme.palette.secondary.background};
        border: 1px solid ${rspTheme.palette.primary.main};
      }
    }

    .mouseType {
      display: none;
    }
  }
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;

  & button[type='submit'] {
    padding: 10px 22px;
  }

  .subscribeEmail {
    flex-grow: 1;
    min-width: 300px;

    .MuiInputBase-input {
      background-color: #ffffff;
      border-radius: 4px;
      margin-bottom: 24px;
    }

    .MuiFilledInput-input {
      padding: 24px 12px 6px;
    }

    .MuiFilledInput-underline::after {
      border-bottom-color: transparent;
    }

    .MuiFilledInput-underline::before {
      border-bottom-color: transparent;
    }

    .MuiFormControl-root {
      position: relative;
    }

    .MuiFormLabel-root {
      color: ${rspTheme.palette.primary.main};
    }

    .MuiFormHelperText-root {
      position: absolute;
      bottom: 2px;
    }

    .MuiFormHelperText-contained {
      margin-left: 0;
      width: 100%;
    }
  }
`
